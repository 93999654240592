
import { Rating, RatingFormDto } from '@/views/ratings/ratings.types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ShiftDetailModal extends Vue {
  @Prop({ default: null }) rating!: Rating | null;

  form = new RatingFormDto();

  get details(): string {
    if (!this.rating) {
      return "";
    }

    return [
      this.rating.shiftDate,  
      this.rating.shiftPosition,
      this.rating.shiftTime
    ].join(' · ')
  }

  save(): void {
    if (!this.rating) {
      return;
    }

    this.form.id = this.rating.id;

    this.$emit('save', this.form);

    this.form = new RatingFormDto();
  }

  dismiss(): void {
    if (!this.rating) {
      return;
    }

    this.form.id = this.rating.id;

    this.$emit('dismiss', this.form);

    this.form = new RatingFormDto();
  }

  onClose(): void {
    this.form = new RatingFormDto();
  }
}
