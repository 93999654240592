import { Component, Mixins } from 'vue-property-decorator';
import { ShiftFilterDto } from './postings.types';
import { mapState } from 'vuex';
import AuthenticateMixin from '../authenticate/authenticate.mixin';

@Component({
  computed: {
    ...mapState("Postings", ["shiftFilters"])
  }
})
export default class PostingsMixin extends Mixins(AuthenticateMixin) {
  timerRef = 0;
  shiftFilters!: ShiftFilterDto;

  getInitials (name: string): string {
    const names = name.split(' ')
    return `${names[0].charAt(0)}${names[1] ? names[1].charAt(0) : ''}`
  }

  getTimes(time: string, field: number, char: string): string {
    const times = time.split(char);
    if (times.length < field) {
      return '';
    }
    return times[field];
  }

  async fetchJobPostings(isGridView: boolean, practiceOfficeId?: number): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    this.$store.dispatch('Postings/resetJobPosts');

    if (isGridView) {
      let shiftFilters = new ShiftFilterDto();
      shiftFilters.practiceOfficeId = practiceOfficeId;

      await this.$store.dispatch('Postings/getJobPostings', shiftFilters);
    }
    else {
      await this.$store.dispatch('Postings/getJobPostings', this.shiftFilters);
    }

    if (practiceOfficeId) {
      await this.$store.dispatch('Postings/getPracticeOfficeRatings', practiceOfficeId);
    }
    
    this.$store.dispatch("Postings/setLoading", false);
  }
}