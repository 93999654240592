
import QrCodeVue from "qrcode.vue";
import { Component, Mixins } from "vue-property-decorator";

import ValidatedFormMixin from "@/common/form/validated-form.mixin";
import { mapState } from "vuex";

import { SmsFormDto } from "./refer.type";

@Component({
  components: {
    QrCodeVue
  },
  computed: {
    ...mapState("Refer", ["form"])
  }
})
export default class FriendReferComponent extends Mixins(ValidatedFormMixin) {
  form!: SmsFormDto;
  success: boolean | null = null;
  loading = false;

  created(): void {
    this.$store.commit("Refer/updateField", {
      path: "form",
      value: new SmsFormDto()
    });
  }

  sendPhone() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        try {
          await this.$store.dispatch("Refer/queueReferMessage", this.form);
          this.form.phoneNumber = "";
          this.success = true;
        }
        catch {
          this.success = false;
        }
        this.loading = false;
      }
    });
  }
}
