import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import OfficeProfilesService from "./office-profiles.service";
import { 
  OfficeProfile,
  OfficeProfileHours,
  OfficeProfilePayrate,
  OfficeProfileUsers,
  UpsertPracticeOffice,
  UpsertPracticeOfficeDefaultRate,
  UpsertPracticeOfficeHours,
  PracticeOfficeUserStatusDto,
  PracticeOfficeStatusDto,
  AddNewUserDto,
  Position,
  PracticeDetails,
  UpsertPracticeOfficeRatesRequest,
  OfficePayrollForm
} from "./office-profiles.types";
import moment from "moment";
import { Roles } from "../authenticate/authenticate.types";
import { generateHexColor } from "@/utils/colorUtils";

@Module({ namespaced: true })
class OfficeProfiles extends VuexModule {
  selectedOfficeProfile: OfficeProfile = new OfficeProfile();
  errorMessage: string | null = null;
  officeProfiles: OfficeProfile[] = [];
  positions: Position[] = [];
  practiceName: string = "";
  isEdit = false;

  @Action({ rawError: true })
  async fetchOfficeProfiles(practiceId: number) {
    try {
      const practiceDetails = await OfficeProfilesService.getPracticeDetails(
        practiceId
      );
      const officeProfileItems = practiceDetails.officeProfiles;
      const officeProfiles: OfficeProfile[] = [];  
      for (const officeProfileItem of officeProfileItems) {
        const payrates: OfficeProfilePayrate[] = [];
        const hours: OfficeProfileHours[] = [];
        const staff: OfficeProfileUsers[] = [];
        for (const payrate of officeProfileItem.payrates) {
          payrates.push({
           position: payrate.name,
           rate: payrate.amount,
           positionId: payrate.positionId,
           practiceOfficeId: officeProfileItem.practiceOfficeId
          });
        }
        for (const hour of officeProfileItem.hours) {
          hours.push({
            break: hour.breakMinutes,
            day: hour.dayOfWeek,
            start: hour.openTime ? moment(hour.openTime, 'HH:mm').format("HH:mm") : "",
            end: hour.closeTime ? moment(hour.closeTime, 'HH:mm').format("HH:mm") : ""
          });
        }
        for (const user of officeProfileItem.users) {
          staff.push({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            initials: user.initials,
            phoneNumber: user.phoneNumber,
            name: user.name,
            inOffice: user.status == 70 &&
              user.practiceOfficeId == officeProfileItem.practiceOfficeId ||
              user.roleName === Roles.CustomerSupport ||
              user.roleName === Roles.PracticeUser,
            userId: user.practiceOfficeUserId,
            practiceOfficeId: user.practiceOfficeId,
            roleName: user.roleName
          });
        }
        officeProfiles.push({
          address1: officeProfileItem.address1,
          address2: officeProfileItem.address2,
          city: officeProfileItem.city,
          email: officeProfileItem.emailAddress,
          hours: hours,
          payrates: payrates,
          name: officeProfileItem.officeName,
          phone: officeProfileItem.phone,
          officePhone: officeProfileItem.officePhone,
          staff: staff,
          state: officeProfileItem.state,
          url: officeProfileItem.website,
          zipCode: officeProfileItem.zipCode,
          practiceId: officeProfileItem.practiceId,
          practiceOfficeId: officeProfileItem.practiceOfficeId,
          color: officeProfileItem.color,
          payroll: officeProfileItem.payroll
        });
      }
      this.context.commit("updateField", {
        path: "officeProfiles",
        value: officeProfiles
      });
      this.context.commit("updateField", {
        path: "practiceName",
        value: practiceDetails.name
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchOfficeUsers(practiceId: number) {
    const officeusers = await OfficeProfilesService.getOfficeUsers(
      practiceId
    );
    const staff: OfficeProfileUsers[] = [];
    for (const officeuser of officeusers) {
      staff.push({
        email: officeuser.email,
        firstName: officeuser.firstName,
        lastName: officeuser.lastName,
        initials: officeuser.initials,
        phoneNumber: officeuser.phoneNumber,
        name: officeuser.name,
        inOffice: officeuser.roleName === Roles.CustomerSupport || officeuser.roleName === Roles.PracticeUser,
        userId: officeuser.practiceOfficeUserId,
        practiceOfficeId: officeuser.practiceOfficeId,
        roleName: officeuser.roleName
      });
    }
    return staff;
  }

  @Action({ rawError: true })
  async upsertPracticeOffice(upsertPracticeOffice: UpsertPracticeOffice) {
    try {
      if (!upsertPracticeOffice.color) {
        upsertPracticeOffice.color = generateHexColor();
      }

      return await OfficeProfilesService.upsertPracticeOffice(upsertPracticeOffice);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async upsertPracticeOfficeDefaultRate(upsertPracticeOfficeDefaultRate: UpsertPracticeOfficeDefaultRate) {
    try {
      await OfficeProfilesService.upsertPracticeOfficeDefaultRate(upsertPracticeOfficeDefaultRate);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async upsertPracticeOfficeRates(request: UpsertPracticeOfficeRatesRequest) {
    try {
      await OfficeProfilesService.UpsertPracticeOfficeRates(request);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async upsertPracticeOfficeHours(upsertPracticeOfficeHours: UpsertPracticeOfficeHours) {
    try {
      await OfficeProfilesService.upsertPracticeOfficeHours(upsertPracticeOfficeHours);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async upsertUserToPracticeOffice(dto: PracticeOfficeUserStatusDto) {
    try {
      await OfficeProfilesService.upsertUserToPracticeOffice(dto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async savePayroll(model: OfficePayrollForm): Promise<void> {
    try {
      await OfficeProfilesService.savePayroll(model);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async setPracticeOfficeStatus(dto: PracticeOfficeStatusDto) {
    try {
      await OfficeProfilesService.setPracticeOfficeStatus(dto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async updatePractice(dto: PracticeDetails) {
    try {
      this.context.commit("updateField", {
        path: "practiceName",
        value: dto.name
      });
      await OfficeProfilesService.updatePractice(dto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchPositions() {
    try {
      return await OfficeProfilesService.getPositions();
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async addNewUser(addNewUserDto: AddNewUserDto) {
    try {
      this.context.commit("updateField", {
        path: "errorMessage",
        value: null
      });
      return await OfficeProfilesService.addNewUser(
        addNewUserDto
      )
    } catch (e: any) {
      const errorMessage = e.response.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }
}

OfficeProfiles.getters = { getField };
OfficeProfiles.mutations = { updateField };

export default OfficeProfiles;