import { RouteConfig } from "vue-router";
import BaseComponent  from "./base.vue";
import { IUserInfo, Roles } from "@/views/authenticate/authenticate.types";
import { POSTINGS_ROUTES } from "@/views/postings/routes";
import { NOTIFICATIONS_ROUTES } from "@/views/notifications/routes";
import { RATINGS_ROUTES } from "@/views/ratings/routes";
import { OFFICE_PROFILES_ROUTES } from "@/views/office-profiles/routes";
import { CONTACTS_ROUTES } from "@/views/contacts/routes";
import { SCHEDULERS_ROUTES } from "@/views/schedulers/routes";
import { DENTAL_TEMPS_ROUTES } from "@/views/dental-temps/routes";
import { CUSTOMER_SUPPORT_ROUTES } from "@/views/customer-support/routes";
import { VACANCY_ROUTES } from "@/views/vacancies/routes";
import { FRIEND_REFER_ROUTES } from "@/views/refer/routes";

const getHomeRedirectPath = (): string | undefined => {
  const userInfoRaw = sessionStorage.getItem("user-info");

  if (!userInfoRaw){
    return;
  }
    
  const userInfo = JSON.parse(userInfoRaw) as IUserInfo;

  if (userInfo.role === Roles.CustomerSupport) {
    return "/customer-support/dashboard"
  }

  return "/postings";
}

const HOME_ROUTE = {
  path: "/",
  redirect: getHomeRedirectPath()
}

export const BASE_ROUTES: RouteConfig[] = [
  {
    path: "",
    name: "base",
    component: BaseComponent,
    children: [
      ...POSTINGS_ROUTES,
      ...NOTIFICATIONS_ROUTES,
      ...RATINGS_ROUTES,
      ...OFFICE_PROFILES_ROUTES,
      ...CONTACTS_ROUTES,
      ...SCHEDULERS_ROUTES,
      ...DENTAL_TEMPS_ROUTES,
      ...CUSTOMER_SUPPORT_ROUTES,
      ...VACANCY_ROUTES,
      ...FRIEND_REFER_ROUTES,
      HOME_ROUTE
    ]
  }
];
