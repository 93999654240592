import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import PostingsService from "./postings.service";
import RatingsService from "@/views/ratings/ratings.service";
import { 
  IOpenJobPost,
  IRequestedJobPost,
  RequestedWorker,
  PracticeOfficeModel,
  JobPostStatus,
  JobPostGroupSetDto,
  Position,
  JobPostGroupResponseStatus,
  IJobGroupPostResponse,
  JobPost,
  AcceptedJobPost,
  ShiftStatus,
  ShiftFormDataDto,
  ShiftFilterDto,
  PayRateStatistics,
  ShiftFormDto,
  ShiftDeleteFormDataDto,
  ShiftDeleteFormDto
} from "./postings.types";
import { Rating, RatingsStatus } from "@/views/ratings/ratings.types";
import postingsService from "./postings.service";

@Module({ namespaced: true })
class Postings extends VuexModule {
  openJobs: IOpenJobPost[] = [];
  requestedJobs: IRequestedJobPost[] = [];
  confirmedJobs: AcceptedJobPost[] = [];
  removedJobs: JobPost[] = [];
  expiredJobs: JobPost[] = [];
  practiceOffices: PracticeOfficeModel[] = [];
  selectedOffice = new PracticeOfficeModel();
  positions: Position[] = [];
  shiftFilters = new ShiftFilterDto();
  payRateStatistics = new PayRateStatistics();
  shiftFormData = new ShiftFormDataDto();
  shiftForm = new ShiftFormDto();
  openRatings: Rating[] = [];
  deleteFormData = new ShiftDeleteFormDataDto();
  deleteForm = new ShiftDeleteFormDto();

  loading = false;

  @Action({ rawError: true })
  setLoading(value: boolean): void {
    this.context.commit("updateField", {
      path: "loading",
      value
    });
  }

  @Action({ rawError: true })
  async deleteShift(): Promise<void> {
    await postingsService.deleteShift(this.deleteForm);
  }

  @Action({ rawError: true })
  async getDeleteFormData(): Promise<void> {
    const formData = await PostingsService.getDeleteFormDataAsync();

    this.context.commit("updateField", {
      path: "deleteFormData",
      value: formData
    });
  }

  @Action({ rawError: true })
  async getPracticeOfficeRatings(practiceOfficeId: number): Promise<void> {
    const ratings = await RatingsService.getOpenByPracticeOfficeAsync(practiceOfficeId);
    
    const openRatings = ratings
      .filter(rating => rating.status == RatingsStatus.Open)
      .map(rating => new Rating(rating));

    this.context.commit("updateField", {
      path: "openRatings",
      value: openRatings
    });
  }

  @Action({ rawError: true })
  async getPracticeOffices(userId: number): Promise<void> {
    try {
      const practiceOffices = await PostingsService.getPracticeOffices(userId);
      this.context.commit("updateField", {
        path: "practiceOffices",
        value: practiceOffices
      });
      if (!this.selectedOffice.practiceOfficeId){
        this.context.commit("updateField", {
          path: "selectedOffice",
          value: practiceOffices[0] ?? new PracticeOfficeModel()
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async saveShift(form: ShiftFormDto): Promise<void> {
    try {
      await PostingsService.saveShift(form);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async getShiftForm(shiftGroupId: number): Promise<void> {
    try {
      const form = await PostingsService.getShiftForm(shiftGroupId);

      if (!form.positionId) {
        return;
      }

      const statistics = await PostingsService.getPayRateStatistics(form.positionId);
      const selectedOffice = this.practiceOffices.find(office => office.practiceOfficeId === form.practiceOfficeId);

      this.context.commit("updateField", {
        path: "shiftForm",
        value: form
      });
      this.context.commit("updateField", {
        path: "payRateStatistics",
        value: statistics
      });
      this.context.commit("updateField", {
        path: "selectedOffice",
        value: selectedOffice
      })
    } catch (e) {
      console.log(e);
    }
  }

  @Action({ rawError: true })
  async getShiftFormData(practiceOfficeId: number): Promise<void> {
    try {
      const data = await PostingsService.getShiftFormData(practiceOfficeId);

      this.context.commit("updateField", {
        path: "shiftFormData",
        value: data
      });
    } catch (e) {
      console.log(e);
    }
  }

  @Action({ rawError: true })
  async getPayRateStatistics(positionId: number): Promise<void> {
    const statistics = await PostingsService.getPayRateStatistics(positionId);

    this.context.commit("updateField", {
      path: "payRateStatistics",
      value: statistics
    });
  }

  @Action({ rawError: true })
  async setJobPostGroupResponseStatus(jobPostGroupSetDto: JobPostGroupSetDto): Promise<void> {
    try {
      await PostingsService.setJobPostGroupResponseStatus(jobPostGroupSetDto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async refreshJobPostings(): Promise<void> {
    if (this.selectedOffice.practiceOfficeId)
      this.context.dispatch('fetchJobPostings', this.selectedOffice.practiceOfficeId);
  }

  @Action({ rawError: true })
  async getJobPostings(model: ShiftFilterDto): Promise<void> {
    try {
      const jobPostings = await PostingsService.getPracticeOfficeJobPostings(model);

      const openJobs: IOpenJobPost[] = jobPostings
        .filter(openJob => openJob.jobPostStatus === JobPostStatus.Open)
        .map(openJob => {
          return {
            ...new JobPost(openJob, ShiftStatus.Open),
            favoritesOnly: openJob.favoritesOnly
          }
        });
      const requestedJobs: IRequestedJobPost[] = jobPostings
        .filter(requestedJob =>
          requestedJob.jobPostStatus === JobPostStatus.Requested &&
          requestedJob.jobGroupPostResponses.some(r => r.jobPostGroupResponseStatus !== JobPostGroupResponseStatus.Cancelled)
        )
        .map(requestedJob => {
          const requestedWorkers: RequestedWorker[] = requestedJob.jobGroupPostResponses
            .map((jobGroupPostResponse: IJobGroupPostResponse) => new RequestedWorker(jobGroupPostResponse));
        
          return {
            ...new JobPost(requestedJob, ShiftStatus.Requested),
            requestedWorkers
          };
        });
      const confirmedJobs: AcceptedJobPost[] = jobPostings
        .filter(confirmedJob => confirmedJob.jobPostStatus === JobPostStatus.Confirmed)
        .map(confirmedJob => new AcceptedJobPost(confirmedJob, JobPostGroupResponseStatus.Confirmed, ShiftStatus.Confirmed));
      const removedJobs: JobPost[] = jobPostings
        .filter(removedJob => removedJob.jobPostStatus === JobPostStatus.Deleted)
        .map(removedJob => new JobPost(removedJob, ShiftStatus.Removed));
      const expiredJobs: JobPost[] = jobPostings
        .filter(expiredJobs => expiredJobs.jobPostStatus === JobPostStatus.JobPostExpired || expiredJobs.jobPostStatus === JobPostStatus.JobPostExpired2)
        .map(expiredJob => new JobPost(expiredJob, ShiftStatus.JobPostExpired))

      this.context.commit("updateField", {
        path: "openJobs",
        value: openJobs
      });
      this.context.commit("updateField", {
        path: "requestedJobs",
        value: requestedJobs
      });
      this.context.commit("updateField", {
        path: "confirmedJobs",
        value: confirmedJobs
      });
      this.context.commit("updateField", {
        path: "removedJobs",
        value: removedJobs
      });
      this.context.commit("updateField", {
        path: "expiredJobs",
        value: expiredJobs
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async addToFavorites(userId: number): Promise<void> {
    try {
      await PostingsService.addToFavorites(this.selectedOffice.practiceOfficeId, userId);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async removeFromFavorites(userId: number): Promise<void> {
    try {
      await PostingsService.removeFromFavorites(this.selectedOffice.practiceOfficeId, userId);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  resetJobPosts(): void {
    this.context.commit("updateField", {
      path: "openJobs",
      value: []
    });
    this.context.commit("updateField", {
      path: "requestedJobs",
      value: []
    });
    this.context.commit("updateField", {
      path: "confirmedJobs",
      value: []
    });
  }
}

Postings.getters = { getField };
Postings.mutations = { updateField };

export default Postings;