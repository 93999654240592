import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import RatingsService from "./ratings.service";
import {
  RatingsStatus,
  Rating,
  RatingFormDto
} from "./ratings.types";
import moment from "moment";

@Module({ namespaced: true })
class Ratings extends VuexModule {
  openRatings: Rating[] = [];
  closedRatings: Rating[] = [];

  @Action({ rawError: true })
  async getPracticeOfficeRatings(practiceId: number): Promise<void> {
    const ratings = await RatingsService.getByPracticeId(practiceId);

    const openRatings = ratings
      .filter(rating => rating.status == RatingsStatus.Open)
      .map(rating => new Rating(rating));

    const closedRatings = ratings
      .filter(rating => rating.status != RatingsStatus.Open)
      .map(rating => {
        const ratedBy = `${rating.raterFirstName} ${rating.raterLastName}`;
        const modifyDate = moment(rating.lastModifiedDate).format("MM/DD/YYYY hh:mm A");

        return new Rating(rating, `Rated by ${ratedBy}, ${modifyDate}`)
      });

    this.context.commit("updateField", {
      path: "openRatings",
      value: openRatings
    });

    this.context.commit("updateField", {
      path: "closedRatings",
      value: closedRatings.sort((a, b) => b.date.localeCompare(a.date))
    });
  }

  @Action({ rawError: true })
  async updateJobPostRating(form: RatingFormDto): Promise<void> {
    try {
      await RatingsService.update(form);
    } catch (e) {
      console.error(e);
    }
  }
}

Ratings.getters = { getField };
Ratings.mutations = { updateField };

export default Ratings;