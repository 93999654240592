
import { Component, Vue, Prop } from "vue-property-decorator";
import { IOpenJobPost, JobPostStatus, PracticeOfficeModel } from "../postings.types";
import HorizontalTrackingComponent from "./components/horizontal-tracking.vue";
import VerticalTrackingComponent from "./components/vertical-tracking.vue";
import { mapState } from "vuex";
import { PayrollType } from "@/views/office-profiles/office-profiles.types";

@Component({
  components: {
    HorizontalTrackingComponent,
    VerticalTrackingComponent
  },
  computed: {
    ...mapState("Postings", ["selectedOffice"])
  }
})
export default class OpenCard extends Vue {
  @Prop() openJob!: IOpenJobPost;

  JobPostStatus = JobPostStatus;
  showHistory = false;
  selectedOffice!: PracticeOfficeModel;

  getClasses(val: boolean): string[] {
    return val ? ['fas', 'fa-check-circle'] : ['far', 'fa-circle'];
  }

  get eodValue(): boolean {
    return this.selectedOffice.payroll?.type === PayrollType.EndOfDay;
  }

  get details(): string {
    return [
      this.openJob.date.toLocaleDateString("en-US", {
        weekday: 'long',
        month: 'numeric',
        year: 'numeric',
        day: 'numeric'
      }),  
      this.openJob.position,
      this.openJob.time,
      this.openJob.rate
    ].join(' · ')
  }

  onDelete(): void {
    this.$emit('delete');
  }
}
