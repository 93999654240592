
import ValidatedFormMixin from "@/common/form/validated-form.mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import { JobPost, ShiftDeleteFormDataDto, ShiftDeleteFormDto } from "../postings.types";

@Component({
  computed: {
    ...mapState("Postings", { formData: "deleteFormData", form: "deleteForm" })
  }
})
export default class ShiftDeleteModal extends Mixins(ValidatedFormMixin) {
  @Prop() shift!: JobPost;

  formData!: ShiftDeleteFormDataDto;
  form!: ShiftDeleteFormDto;

  mounted(): void {
    this.$store.commit("Postings/updateField", {
      path: "deleteForm",
      value: new ShiftDeleteFormDto()
    });
  }

  closeModal(): void {
    this.$bvModal.hide("shift-delete-modal");
  }

  async onDelete(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.$store.dispatch("Postings/setLoading", true);

        this.form.id = this.shift.id;
        await this.$store.dispatch("Postings/deleteShift");

        this.$store.dispatch("Postings/setLoading", false);
        
        this.$emit("delete");
        this.closeModal();
      }
    });
  }

  onCancel(): void {
    this.closeModal();
  }
}
