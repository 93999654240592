
import "./styles/posting-table.scss";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import ConfirmedCard from "./cards/confirmed-card.vue";
import OpenCard from "./cards/open-card.vue";
import RequestedCard from "./cards/requested-card.vue";
import GradingCard from "./cards/grading-card.vue";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import { 
  AcceptedJobPost,
  IOpenJobPost,
  IRequestedJobPost,
  JobPost,
  JobPostGroupResponseStatus,
  JobPostStatus,
  PracticeOfficeModel
} from "./postings.types";
import PostingsMixin from "./postings.mixin";
import { Rating, RatingFormDto, RatingsStatus } from "../ratings/ratings.types";
import ShiftDeleteModal from "./modals/shift-delete-modal.vue";

@Component({
  components: { 
    OpenCard,
    RequestedCard,
    ConfirmedCard,
    GradingCard,
    ShiftDeleteModal
  },
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice", 
      "openJobs", 
      "requestedJobs",
      "confirmedJobs",
      "openRatings"
    ])
  }
})
export default class PostingGrid extends Mixins(AuthenticateMixin, PostingsMixin) {
  @Prop() currentUserFilter!: boolean;

  openJobs!: IOpenJobPost[];
  requestedJobs!: IRequestedJobPost[];
  confirmedJobs!: AcceptedJobPost[];
  openRatings!: Rating[];
  selectedOffice!: PracticeOfficeModel;

  selectedShift: JobPost | null = null;

  async created(): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);
    
    if (this.selectedOffice.practiceOfficeId) {
      await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);
    }
    this.timerRef = setInterval(() => this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId), 60000);

    this.$store.dispatch("Postings/setLoading", false);
  }

  destroyed(): void {
    clearInterval(this.timerRef);
  }

  @Watch('selectedOffice')
  async onSelectedOfficeChange(): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);

    this.$store.dispatch("Postings/setLoading", false);
  }

  async onDelete(): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);
    
    await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);
    
    this.$store.dispatch("Postings/setLoading", false);
  }

  async onDeleteClick(shift: JobPost): Promise<void> {
    this.selectedShift = shift;

    this.$bvModal.show("shift-delete-modal");
  }

  async pendingData(userId: number, jobPostGroupResponseId: number): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    await this.$store.dispatch("Postings/setJobPostGroupResponseStatus", {
      jobPostGroupResponseId,
      userId,
      status: JobPostGroupResponseStatus.Confirmed
    });
    await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);

    this.$store.dispatch("Postings/setLoading", false);
  }

  getJobPosts(jobPosts: JobPost[]): JobPost[] {
    if (!this.currentUserFilter){
      return jobPosts;
    }

    return jobPosts.filter(jobPost => jobPost.postedByUserId === this.userInfo()?.sub);
  }

  async saveRating(form: RatingFormDto): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    await this.$store.dispatch("Ratings/updateJobPostRating", {
      id: form.id,
      rating: form.rating,
      comments: form.comments,
      status: RatingsStatus.Active,
      raterUserId: this.userInfo()?.sub,
      noShow: form.noShow,
      sickCall: form.sickCall
    } as RatingFormDto);
    
    await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);

    this.$store.dispatch("Postings/setLoading", false);
  }

  async dismissRating(form: RatingFormDto): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    await this.$store.dispatch("Ratings/updateJobPostRating", {
      id: form.id,
      rating: 0,
      comments: null,
      status: RatingsStatus.Dismissed,
      raterUserId: this.userInfo()?.sub
    } as RatingFormDto);

    await this.fetchJobPostings(true, this.selectedOffice.practiceOfficeId);

    this.$store.dispatch("Postings/setLoading", false);
  }
}
