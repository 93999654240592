import httpAuth from "@/common/http/http-common";
import { SmsFormDto } from "./refer.type";

class ReferService {
  async queueReferMessage(form: SmsFormDto) {
    const { data } = await httpAuth().put(`sms/refer`, form);

    return data;
  }
}

export default new ReferService();