
import { Component, Vue, Prop } from "vue-property-decorator";
import { EmployeeDetailsModel, JobPostStatus } from "../postings.types";
import { format as formatDate } from '@/utils/dateUtils';
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("Postings", ["loading"])
  }
})
export default class EmployeeDetails extends Vue {
  @Prop() employeeDetails!: EmployeeDetailsModel;
  @Prop() modalShow!: boolean;
  @Prop() status!: JobPostStatus;

  loading!: boolean;

  isFavorite: boolean | null = null;

  formatDate = formatDate;

  get favoriteText() {
    return this.employeeDetails?.isFavorite ? "Remove from Favorites" : "Add to Favorites";
  }

  get isNotPending(){
    return this.status != JobPostStatus.Pending;
  }

  async toggleFavorite() {
    this.$store.dispatch("Postings/setLoading", true);
    var newValue = !this.employeeDetails.isFavorite;
    var verb = newValue ? "addToFavorites" : "removeFromFavorites";
    await this.$store.dispatch(`Postings/${verb}`, this.employeeDetails.id);
    this.employeeDetails.isFavorite = newValue;
    this.$store.dispatch("Postings/setLoading", false);
  }
  
  created() {
    this.isFavorite = this.employeeDetails?.isFavorite;
  }

  getBackgroundColor() {
    switch(+this.status) {
      case JobPostStatus.Open:
        return "background: #109730";
      case JobPostStatus.Requested:
        return "background: #F1950B;";
      case JobPostStatus.Pending:
        return "background: #F31313;";
      case JobPostStatus.Confirmed:
        return "background: #315564;";
      default:
        return "";
    }
  }

  getColor() {
    switch(+this.status) {
      case JobPostStatus.Open:
        return "color: #109730";
      case JobPostStatus.Requested:
        return "color: #F1950B;";
      case JobPostStatus.Pending:
        return "color: #F31313;";
      case JobPostStatus.Confirmed:
        return "color: #315564;";
      default:
        return "";
    }
  }

  getLine() {
    switch(+this.status) {
      case JobPostStatus.Open:
        return "border-top: 2px solid #109730";
      case JobPostStatus.Requested:
        return "border-top: 2px solid #F1950B;";
      case JobPostStatus.Pending:
        return "border-top: 2px solid #F31313;";
      case JobPostStatus.Confirmed:
        return "border-top: 2px solid #315564;";
      default:
        return "";
    }
  }

  getTitle() {
    switch(+this.status) {
      case JobPostStatus.Open:
        return "Open";
      case JobPostStatus.Requested:
        return "Requested";
      case JobPostStatus.Pending:
        return "Pending";
      case JobPostStatus.Confirmed:
        return "Confirmed";
      default:
        return "";
    }
  }

  closeModal() {
    if (this.isFavorite != this.employeeDetails?.isFavorite) {
      this.$store.dispatch('Postings/refreshJobPostings');
    }
    this.$emit('update:modalShow', false);
  }
}
