
import { Rating, RatingFormDto } from "@/views/ratings/ratings.types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class GradingCardComponent extends Vue {
  @Prop() openRating!: Rating;

  form: RatingFormDto = new RatingFormDto();

  get details(): string {
    return [
      this.openRating.shiftDate,  
      this.openRating.shiftPosition,
      this.openRating.shiftTime
    ].join(' · ')
  }

  save(): void {
    this.form.id = this.openRating.id;

    this.$emit('save', this.form)
  }

  dismiss(): void {
    this.form.id = this.openRating.id;

    this.$emit('dismiss', this.form)
  }
}
