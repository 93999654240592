
import { Component, Prop, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { mapState } from "vuex";
import { 
  ShiftFormDto,
  PracticeOfficeModel,
  Positions,
  ShiftFormDataDto,
  PayRateStatistics
} from "../postings.types";
import AuthenticateMixin from "../../authenticate/authenticate.mixin";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";

@Component({
  components: {
    DatePicker
  },
  computed: {
    ...mapState("Postings", {
      selectedOffice: "selectedOffice",
      practiceOffices: "practiceOffices",
      formData: "shiftFormData",
      form: "shiftForm",
      statistics: "payRateStatistics",
      loading: "loading"
    }),
  },
})
export default class PostShift extends Mixins(AuthenticateMixin) {
  @Prop() modalShow!: boolean;
  @Prop() isGridView!: boolean;

  selectedDates: Date[] = [];
  form!: ShiftFormDto;
  formData!: ShiftFormDataDto;
  statistics!: PayRateStatistics;
  selectedOffice!: PracticeOfficeModel;
  practiceOffices!: PracticeOfficeModel[];
  loading!: boolean;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  get postToOptions(): { text: string, value: number, disabled: boolean }[] {
    var isDisabled = this.formData.favoriteCount === 0;
    return [
      { text: 'All', value: 0, disabled: isDisabled }, 
      { text: 'Favorites Only', value: 1, disabled: isDisabled },
    ];
  }

  get otherFormGroupDisabled(): boolean {
    return this.form.positionId != Positions.DentalHygienist;
  }

  get postTo(): number[] {
    return [this.form.postTo];
  }

  set postTo(value: number[]) {
    if (value.length === 0) {
      this.form.postTo = this.form.postTo === 0 ? 1 : 0;
      return;
    }

    var newValue = value[value.length - 1];
    if (newValue != this.form.postTo)
      this.form.postTo = newValue;
  }

  get minDate(): Date {
    return new Date();
  }

  get payRate(): string | undefined {
    return this.form.payRate?.toFixed(2);
  }

  get positionName(): string | undefined {
    return this.formData.positions.find(option => option.value === this.form.positionId)?.text;
  }
  
  onDateInput(dates: Date[]) {
    if (dates.length === 0 && this.form.shiftGroupId) {
      return;
    }

    if (!this.form.timeIn && !this.form.timeOut) {
      this.form.timeIn = "08:00:00";
      this.form.timeOut = "17:00:00";
    }

    this.selectedDates = dates;
  }

  disabledDate(date: Date) {
    if (this.form.shiftGroupId) {
      const formDate = new Date(new Date(this.form.dates[0]).setHours(0, 0, 0, 0));
      
      return formDate.getTime() !== date.getTime();
    }

    const today = new Date().setHours(0, 0, 0, 0);
    
    return date < new Date(today);
  }

  disabledCalendarChanger(date: Date) {
    return this.disabledDate(date);
  }

  formatDate(date: Date) {
    const getSuffix = (day: number) => {
        if ([11, 12, 13].includes(day)) return "th";
        return ["st", "nd", "rd"][day % 10 - 1] || "th";
    };

    const options = { month: "short", day: "numeric", year: "numeric" } as Intl.DateTimeFormatOptions;
    const formatted = date.toLocaleDateString("en-US", options);
    
    return formatted.replace(/(\d+)/, (day) => day + getSuffix(Number(day)));
  }

  async onOfficeSelect(value: PracticeOfficeModel): Promise<void> {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
    this.$store.dispatch("Postings/setLoading", true);
    await this.fetchData();
    this.$store.dispatch("Postings/setLoading", false);
  }

  setPayRate(value: string | undefined): void {
    if (value) 
      this.form.payRate = parseFloat(value);
  }

  async mounted(): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);
    await this.fetchData();
    if (!this.form.shiftGroupId) {
      this.$store.commit("Postings/updateField", {
        path: "payRateStatistics",
        value: new PayRateStatistics()
      })
    }
    this.selectedDates = (this.form.dates as string[]).map(d => new Date(d));
    this.$store.dispatch("Postings/setLoading", false);
  }

  validateState(ref: string): boolean | null {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async fetchData() : Promise<void> {
    await this.$store.dispatch("Postings/getShiftFormData", this.selectedOffice.practiceOfficeId);
  }

  async createJobPost(): Promise<void> {
    this.$store.dispatch("Postings/setLoading", true);

    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.form.practiceOfficeId = this.selectedOffice.practiceOfficeId;

        this.form.breakTime = 0;
        this.form.dates = this.selectedDates.map(date =>  date.toLocaleDateString("sv"));

        if (this.form.channel){
          this.form.channel = parseInt(this.form.channel.toString());
        }

        await this.$store.dispatch("Postings/saveShift", this.form);

        this.$emit('reload');
        this.$emit('update:modalShow', false);
      }

      this.$store.dispatch("Postings/setLoading", false);
    });
  }

  async onPositionSelect(value: number): Promise<void> {
    const officeRates = this.formData.rates.find(rate => rate.positionId === value);

    this.form.payRate = officeRates?.amount ?? null;

    if (value != Positions.DentalHygienist) {
      this.form.channel = undefined;
    }

    this.$store.dispatch("Postings/setLoading", true);
    await this.$store.dispatch("Postings/getPayRateStatistics", value);
    this.$store.dispatch("Postings/setLoading", false);
  }

  closeModal(): void {
    this.$emit('update:modalShow', false);
  }
}
