import moment from "moment";

export class Rating {
  constructor(model: IRatingDto, log?: string) {
    this.id = model.id;
    this.raterUserId = model.raterUserId;
    this.dtName = `${model.dtFirstName} ${model.dtLastName}`;
    this.officeName = model.officeName;
    this.comments = model.comments;
    this.rating = model.rating;
    this.noShow = model.noShow;
    this.sickCall = model.sickCall;
    this.status = model.status;
    this.date = moment(model.startDateOnly).format("MM/DD/YYYY");
    this.log = log ?? "";
    
    const start = moment(model.shiftStartDateTime).format("h:mm A");
    const end = moment(model.shiftEndDateTime).format("h:mm A");
    const date = moment(model.startDateOnly).format("dddd, MM/DD/YYYY");

    this.shiftTime = `${start} - ${end}`;
    this.shiftPosition = model.shiftPosition;
    this.shiftDate = date;
  }

  id: number;
  raterUserId: number;
  dtName: string;
  officeName: string;
  comments: string | null;
  rating: number;
  noShow: boolean;
  sickCall: boolean;
  status: RatingsStatus
  shiftPosition: string;
  shiftTime: string
  shiftDate: string;
  date: string;
  log: string;
  disabled = true;
}

export class RatingFormDto {
  id = 0;
  raterUserId = 0;
  rating = 0;
  comments: string | null = null;
  status = 0;
  sickCall = false;
  noShow = false;
}

export interface IRatingDto {
  id: number,
  raterUserId: number,
  dtFirstName: string,
  dtLastName: string,
  raterFirstName: string,
  raterLastName: string,
  officeName: string,
  comments: string,
  rating: number,
  status: number,
  noShow: boolean,
  sickCall: boolean,
  shiftPosition: string,
  shiftRate: number,
  shiftStartDateTime: string,
  shiftEndDateTime: string,
  startDateOnly: Date,
  lastModifiedDate: Date
}

export enum RatingsStatus {
  Open = 92,
  Active = 93,
  Dismissed = 95
}