import { Component, Vue } from "vue-property-decorator";
import { Rating } from "./ratings.types";
import { mapState } from "vuex";

interface RatingFilter {
  date: string;
  member: string;
  officeName: string;
  rating: string;
  comments: string;
}

@Component({
  computed: {
    ...mapState("Ratings", ["openRatings", "closedRatings"])
  },
})
export default class RatingMixin extends Vue {
  openRatings!: Rating[];
  closedRatings!: Rating[];

  fields = [
    { key: 'date', label: 'Date', sortable: true },
    { key: 'dtName', label: 'Team Member', sortable: true },
    { key: 'officeName', label: 'Office', sortable: true },
    { key: 'rating', label: 'Shift Rating', sortable: true },
    { key: 'noShow', label: 'No Show', sortable: false, thStyle: {verticalAlign: 'top'} },
    { key: 'sickCall', label: 'Sick Call', sortable: false, thStyle: { verticalAlign: 'top' }},
    { key: 'comments', label: 'Comments', sortable: true }
  ];

  filters = {
    date: '',
    member: '',
    officeName: '',
    rating: '',
    comments: ''
  } as RatingFilter;

  get filtered(): Rating[] {
    const filtered = this.closedRatings.filter(item =>
      Object.keys(this.filters)
        .every(key => 
          String(item[key as keyof Rating])
            .toLowerCase()
            .includes(this.filters[key as keyof RatingFilter].toString().toLowerCase())
      ));

    return filtered.length > 0 ? filtered : []
  }
}