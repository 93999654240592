
import { Component, Prop, Mixins } from "vue-property-decorator";
import EmployeeDetails from "../modals/employee-details.vue"
import PostingsMixin from "../postings.mixin";
import HorizontalTrackingComponent from "./components/horizontal-tracking.vue";
import { 
  IRequestedJobPost,
  EmployeeDetailsModel,
  RequestedWorker,
  JobPostStatus,
  PracticeOfficeModel
} from "../postings.types";
import { mapState } from "vuex";
import { PayrollType } from "@/views/office-profiles/office-profiles.types";

@Component({
  components: {
    EmployeeDetails,
    HorizontalTrackingComponent
  },
  computed: {
    ...mapState("Postings", ["selectedOffice"])
  }
})
export default class RequestedCard extends Mixins(PostingsMixin) {
  @Prop() requestedJob!: IRequestedJobPost;

  employeeDetails: EmployeeDetailsModel = new EmployeeDetailsModel;
  JobPostStatus = JobPostStatus;
  modalShow = false;
  selectedOffice!: PracticeOfficeModel;

  getClasses(val: boolean): string[] {
    return val ? ['fas', 'fa-check-circle'] : ['far', 'fa-circle'];
  }

  get eodValue(): boolean {
    return this.selectedOffice.payroll?.type === PayrollType.EndOfDay;
  }


  get headerText(): string {
    return this.requestedJob.requestedWorkers.length > 1 
      ? 'Yatzee! We have found multiple people that want to help.'
      : 'Bingo! We have found someone who want to help.';
  }

  get details(): string {
    return [
      this.requestedJob.date.toLocaleDateString("en-US", {
        weekday: 'long',
        month: 'numeric',
        year: 'numeric',
        day: 'numeric'
      }), 
      this.requestedJob.position,
      this.requestedJob.time,
      this.requestedJob.rate
    ].join(' · ')
  }

  getEmployeeDetails(details: RequestedWorker): void {
    this.employeeDetails = {
      name: details.name,
      rating: details.rating,
      phone: details.phone,
      email: details.email,
      date: this.requestedJob.date,
      position: this.requestedJob.position,
      rate: this.requestedJob.rate,
      timeIn: this.getTimes(this.requestedJob.time, 0, " - "),
      timeOut: this.getTimes(this.requestedJob.time, 1, " - "),
      initials: this.getInitials(details.name),
      isFavorite: details.isFavorite,
      id: details.userId
    };
    this.modalShow = true;
  }

  pending(userId: number, jobPostGroupResponseId: number): void {
    this.$emit('pending', userId, jobPostGroupResponseId);
  }

  onDelete(): void {
    this.$emit('delete');
  }
}
