import { RouteConfig } from "vue-router";
import FriendReferComponent from "./refer.vue";
import { Roles } from "../authenticate/authenticate.types";

export const FRIEND_REFER_ROUTES: RouteConfig[] = [
  {
    path: "/refer-a-friend",
    name: "FriendReferComponent",
    component: FriendReferComponent,
    meta: {
          roles: [Roles.PracticeUser, Roles.OfficeUser],
          nav: true,
          navTitle: "Tell a friend about us",
          navIcon: "fa-bullhorn"
        }
  }
];