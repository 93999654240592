import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import { SmsFormDto } from "./refer.type";
import ReferService from "./refer.service";

@Module({ namespaced: true })
class Refer extends VuexModule {
  form = new SmsFormDto();

  @Action({ rawError: true })
    async queueReferMessage(form: SmsFormDto) {
      await ReferService.queueReferMessage(form);
    }
}

Refer.getters = { getField };
Refer.mutations = { updateField };

export default Refer;