export class OfficeProfile {
  practiceId: number = 0;
  practiceOfficeId: number = 0;
  name: string = "";
  address1: string = "";
  address2: string | null = null;
  city: string = "";
  state: string = "";
  zipCode: string = "";
  phone: string | null = null;
  officePhone: string = "";
  email: string | null = null;
  url: string = "";
  staff: OfficeProfileUsers[] = [];
  hours: OfficeProfileHours[] = [];
  payrates: OfficeProfilePayrate[] = [];
  color: string = "black";
  payroll? = new OfficePayroll();
}

export interface IAddress {
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
}

export class OfficeModel implements IAddress {
  practiceId: number = 0;
  practiceOfficeId: number = 0;
  practiceName: string = "";
  officeName: string = "";
  address1: string = "";
  address2: string | null = null;
  city: string = "";
  state: string = "";
  zipCode: string = "";
  website: string = "";
  phone: string | null = null;
  officePhone: string = "";
  email: string | null = null;
  color: string = "black";
  payroll? = new OfficePayroll();
}

export class OfficePayrollForm {
  id?: number;
  employeeType?: EmployeeType;
  type?: PayrollType;
  description?: string;
  practiceOfficeId?: number;
}

export class OfficePayroll {
  id?: number;
  employeeType?: EmployeeType;
  type?: PayrollType;
  description?: string;
}

export class OfficeProfileHours {
  day: string = "";
  start: string = "";
  end: string = "";
  break: string = "";
}

export class OfficeProfileUsers {
  practiceOfficeId: number | null = null;
  name: string = "";
  firstName: string = "";
  lastName: string = "";
  initials: string = "";
  phoneNumber: string = "";
  email: string = "";
  inOffice: boolean = false;
  userId: number = 0;
  roleName: string = "";
}

export class OfficeProfilePayrate {
  positionId: number = 0;
  position: string = "";
  rate: number = 0;
  practiceOfficeId: number = 0;
}

export class UpsertPracticeOffice {
  practiceId: number | null = null;
  practiceOfficeId: number | null = null;
  officeName: string = "";
  address1: string = "";
  address2: string | null = null;
  city: string = "";
  state: string = "";
  zipCode: string = "";
  image: Uint8Array | null = null;
  phone: string = "";
  emailAddress: string | null = null;
  website: string = "";
  color: string = "black"
}

export class UpsertPracticeOfficeDefaultRate {
  positionId: number = 0;
  practiceOfficeId: number = 0;
  amount: number = 0.00;
}

export class UpsertPracticeOfficeRatesRequest {
  Rates: UpsertPracticeOfficeDefaultRate[] = [];
  PracticeOfficeId: number = 0;
}

export class UpsertPracticeOfficeHours {
  practiceOfficeId: number = 0;
  sundayOpenTime: string = "";
  sundayCloseTime: string = "";
  mondayOpenTime: string = "";
  mondayCloseTime: string = "";
  tuesdayOpenTime: string = "";
  tuesdayCloseTime: string = "";
  wednesdayOpenTime: string = "";
  wednesdayCloseTime: string = "";
  thursdayOpenTime: string = "";
  thursdayCloseTime: string = "";
  fridayOpenTime: string = "";
  fridayCloseTime: string = "";
  saturdayOpenTime: string = "";
  saturdayCloseTime: string = "";
  sundayBreakMinutes: number | null = null;
  mondayBreakMinutes: number | null = null;
  tuesdayBreakMinutes: number | null = null;
  wednesdayBreakMinutes: number | null = null;
  thursdayBreakMinutes: number | null = null;
  fridayBreakMinutes: number | null = null;
  saturdayBreakMinutes: number | null = null;
}

export class PracticeDetails {
  practiceId: number = 0;
  name: string | null = null;
}

export class Position {
  positionId: number = 0;
  name: string = "";
  description: string = "";
  sortOrder: number = 0;
  status: number = 0;
}

export class PracticeOfficeUserStatusDto {
  userId: number = 0;
  practiceOfficeId: number = 0;
  status: number = 0;
}

export class PracticeOfficeStatusDto {
  practiceOfficeId: number = 0;
  status: number = 0;
}

export class AddNewUserDto {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phoneNumber: string = "";
  password: string = "";
  practiceOfficeId: number | null = null;
}

export enum PracticeOfficeUserStatus {
  Active = 70,
  Inactive = 71
}

export enum PracticeOfficeStatus {
  Active = 70,
  Inactive = 71
}

export enum EmployeeType {
  WTwo = 1,
  OneZeroNineNine = 2
}

export enum PayrollType {
  EndOfDay = 1,
  Weekly = 2,
  Biweekly = 3,
  DirectDeposit = 4,
  Other = 5
}