
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import UserEditModal from "@/common/user/user-edit-modal.vue";
import { IUserInfo } from "../authenticate/authenticate.types";
import { FRIEND_REFER_ROUTES } from "@/views/refer/routes";
import { RouteConfig } from "vue-router";

const basePath = require('@/assets/notification-icons.svg');

@Component({
  components: {
    UserEditModal
  }
})
export default class Header extends Mixins(AuthenticateMixin) {
  modalShow = false;
  currentUser?: IUserInfo = this.userInfo();

  referPath = "/refer-a-friend";
  scrollPosition = 2;
  scrollCount = 0;
  scrollSpeed = 2;
  scrollPaused = false;
  intervalId?: number;

  mounted() {
    this.startScrolling();
  }

  isReferVisible(): boolean {
    const route = FRIEND_REFER_ROUTES.find((r: RouteConfig) => r.path === this.referPath);
    return route?.meta?.roles?.some((role: string) => role === this.userInfo()?.role);
  }

  startScrolling() {
    this.intervalId = setInterval(() => {
      if (this.scrollCount >= 11) {
        clearInterval(this.intervalId);
        return;
      }

      this.scrollPosition -= this.scrollSpeed;

      if (this.scrollPosition <= 0) {
        clearInterval(this.intervalId);
        
        setTimeout(() => {
          this.resumeScrolling();
        }, 5000);
      }

      if (this.scrollPosition < -180) {
        this.scrollPosition = 180;
        this.scrollCount++;
      }
    }, 20);
  }

  resumeScrolling() {
    this.intervalId = setInterval(() => {
      if (this.scrollCount === 10) {
        this.scrollPosition = 0;
        return;
      }

      this.scrollPosition -= this.scrollSpeed;

      if (this.scrollPosition < -180) {
        clearInterval(this.intervalId);
        this.scrollPosition = 180;
        this.scrollCount++;
        this.startScrolling();
      }
    }, 20);
  }

  getCustomIconRoute(iconName: string): string {
    return basePath + "#" + iconName;
  }

  get currentUserId(): number | undefined {
    return this.currentUser?.sub;
  }

  get initials(): string {
    if (this.currentUser && this.currentUser?.firstName && this.currentUser?.lastName) {
      return `${this.currentUser?.firstName[0]}${this.currentUser?.lastName[0]}`.toUpperCase();
    }
    
    return "";
  }

  get profileImgUrl(): string {
    return this.currentUser?.profileImgUrl ?? "";
  }

  get showBackToDashboard(): boolean {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    return Boolean(supportUserInfo) && Boolean(supportToken);
  }

  logoutPush(): void {
    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.removeItem("user-info");
    sessionStorage.removeItem("token");
    this.$store.commit("reset");
    this.$router.push("/login");
  }

  backToDashboard(): void {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    if (!supportUserInfo || !supportToken){
      return;
    }

    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.setItem("user-info", supportUserInfo);
    sessionStorage.setItem("token", supportToken);

    window.location.replace("/customer-support/dashboard");
  }

  showEditProfileModal(): void {
    this.modalShow = true;
  }

  onUserEditSuccess(): void {
    this.currentUser = this.userInfo();
  }
}
