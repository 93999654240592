
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActionButton extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() icon!: string;
  @Prop({ default: "fas" }) iconStyle!: string;
  @Prop({ default: false }) activeDisable!: boolean;

  onClick(): void {
    this.$emit('click');
  }
}
