import httpAuth from "@/common/http/http-common";
import { 
  IRatingDto,
  RatingFormDto
} from "./ratings.types";

class RatingsService {
  async getByPracticeId(practiceId: number): Promise<IRatingDto[]> {
    const { data } = await httpAuth().get(
      `rating/practice/${practiceId}/dental-temp/all`
    );

    return data;
  }

  async getOpenByPracticeOfficeAsync(practiceOfficeId: number): Promise<IRatingDto[]> {
    const { data } = await httpAuth().get(
      `rating/practice-office/${practiceOfficeId}/open`
    );

    return data;
  }

  async update(model: RatingFormDto) {
    const { data } = await httpAuth().put(`rating`, model);

    return data;
  }
}

export default new RatingsService();