import { render, staticRenderFns } from "./postings.vue?vue&type=template&id=5ada2a17&scoped=true&lang=pug"
import script from "./postings.vue?vue&type=script&lang=ts"
export * from "./postings.vue?vue&type=script&lang=ts"
import style0 from "./postings.vue?vue&type=style&index=0&id=5ada2a17&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ada2a17",
  null
  
)

export default component.exports