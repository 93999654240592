
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import {
  RatingFormDto,
  RatingsStatus
} from "./ratings.types";
import { EmployeeDetails, EmployeeKey } from "@/components/employee-details";
import ConfirmDialog from "@/views/dialog/confirm-dialog.vue";
import ClosedRatingTable from "./closed-rating-table.vue";
import OpenRatingTable from "./open-rating-table.vue";

@Component({
  components: {
    ClosedRatingTable,
    OpenRatingTable,
    EmployeeDetails,
    ConfirmDialog
  },
})
export default class RatingsComponent extends Mixins(AuthenticateMixin) {
  loading = true;
  confirmModalShow = false;
  form: RatingFormDto | null = null;
  currentEmployee : EmployeeKey | null = null;
  isEmployeeDetailsVisible = false;
      
  showDetails(employeeId: number): void {
    this.currentEmployee = { userId: employeeId, practiceId: this.userInfo()?.practiceId ?? 0 };
    this.isEmployeeDetailsVisible = true;
  }

  showWarning(isChecked: boolean): void {
    if (isChecked) {
      this.confirmModalShow = true;
    }
  }

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.loading = true;

    await this.$store.dispatch("Ratings/getPracticeOfficeRatings", this.userInfo()?.practiceId);

    this.loading = false;
  }

  async dismiss(form: RatingFormDto): Promise<void> {
    this.loading = true;

    await this.$store.dispatch("Ratings/updateJobPostRating", {
      id: form.id,
      rating: 0,
      comments: null,
      status: RatingsStatus.Dismissed,
      raterUserId: this.userInfo()?.sub
    });
    await this.$store.dispatch("Ratings/getPracticeOfficeRatings", this.userInfo()?.practiceId);

    this.loading = false;
  }

  async save(item: RatingFormDto): Promise<void> {
    if (item.noShow) {
      this.confirmModalShow = true;
      this.form = item;
    } else {
      await this.saveInternal(item);
    }
  }

  onReject(): void {
    if (this.form != null){
      this.form.noShow = false;
    }

    this.form = null;
  }

  async confirm(): Promise<void> {
    if (this.form != null) {
      await this.saveInternal(this.form);
    }

    this.form = null;
  }

  async saveInternal(item: RatingFormDto): Promise<void> {
    this.loading = true;

    await this.$store.dispatch("Ratings/updateJobPostRating", {
      id: item.id,
      rating: item.rating,
      comments: item.comments,
      status: RatingsStatus.Active,
      raterUserId: this.userInfo()?.sub,
      noShow: item.noShow,
      sickCall: item.sickCall
    });
    await this.$store.dispatch("Ratings/getPracticeOfficeRatings", this.userInfo()?.practiceId);

    this.loading = false;
  }
}
